import React from "react"
import { graphql } from "gatsby"

import ResourceIndexPage from "../../components/resources/resourceIndexPage"

const HRToolKit = ({ data }) => {
  const {
    hrkit: {
      image,
      seo: { description, titleseo },
      topblurb,
      title,
    },
    resources: { nodes: resourceNodes },
    categories: { nodes: categoryNodes },
  } = data
  return (
    <ResourceIndexPage
      categories={categoryNodes}
      resources={resourceNodes}
      image={image.childImageSharp.fluid}
      topblurb={topblurb}
      title={title}
      description={description}
      titleseo={titleseo}
    />
  )
}

export const query = graphql`
  {
    hrkit: strapiHrtoolkit {
      seo {
        titleseo
        description
      }
      title
      topblurb
      image {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100, cropFocus: NORTH) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    resources: allStrapiResource(
      filter: { resource_category: { type: { eq: "hr" } } }
    ) {
      nodes {
        thumbnailblurb
        thumb {
          childImageSharp {
            fluid(maxWidth: 160, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        slug
        Title
        resource_category {
          Title
          type
        }
      }
    }
    categories: allStrapiResourceCategory(filter: { type: { eq: "hr" } }) {
      nodes {
        type
        Title
      }
    }
  }
`

export default HRToolKit
